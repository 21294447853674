import React from "react"
import Slider from "react-animated-slider"
import "react-animated-slider/build/horizontal.css"
import { Container, Row, Col } from "reactstrap"

import "./style.scss"

const content = [
  {
    id: 'test1',
    description:
      "Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Cras justo odio, dapibus ac facilisis.",
    image: "https://i.imgur.com/ZXBtVw7.jpg",
    user: "Luan Gjokaj",
    userProfile: "https://i.imgur.com/JSW6mEk.png",
  },
  {
    id: 'test2',
    description:
      "Nullam id dolor id nibh ultricies vehicula ut id elit. Cras mattis consectetur purus sit amet fermentum. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Donec sed odio dui.",
    image: "https://i.imgur.com/DCdBXcq.jpg",
    user: "Erich Behrens",
    userProfile: "https://i.imgur.com/0Clfnu7.png",
  },
  {
    id: 'test3',
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Duis mollis, est non commodo luctus, nisi erat porttitor ligula.",
    image: "https://i.imgur.com/DvmN8Hx.jpg",
    user: "Bruno Vizovskyy",
    userProfile: "https://i.imgur.com/4KeKvtH.png",
  },
]

const Testimonial = () => (
  <section className={`slide-testimonial`}>
    <Container>
      <Row>
        <Col>
          <h2 className={`title`}>O que dizem nosso alunos</h2>
        </Col>
      </Row>
    </Container>
    <Slider className="slider-wrapper container" duration={1000}>
      {content.map((item) => (
        <div key={item.id} className={`slider-content ${item.id}`}>
          <div className="inner">
            <p>{item.description}</p>
          </div>
          <section>
            <img src={item.userProfile} alt={item.user} />
            <span>
              <strong>{item.user}</strong>
            </span>
          </section>
        </div>
      ))}
    </Slider>
  </section>
)

export default Testimonial
