import React from "react"
import { Container, Row } from 'reactstrap'

import './style.scss'

const Mission = props => (
  <section className={`our-mission`}>
    <Container>
      <Row>{props.children}</Row>
    </Container>
  </section>
)

export default Mission
