import React from 'react'

import Layout from '../components/Layout'
import Seo from '../components/seo'
import About from '../components/About'

import Testimonial from '../components/Testimonial'
import CtaContainer from '../components/CtaContainer'
import Mission from '../components/Mission'
import MissionItem from '../components/MissionItem'
import Hero from '../components/Hero'

const AboutPage = () => (
  <Layout>
    <Seo title="Quem Somos | Talk of the Town" />

    <Hero text="Sobre nós" />

    <About />

    <Mission>
      <MissionItem iconOne title="Cursos Online" description="Quuntur magni dolores eos qui ratione voluptatem sequi nesciunt neque porro quisquam est, qui dolorem ipsum quiaolor sit amet." />
      <MissionItem iconTwo title="Nossa Missão" description="Quuntur magni dolores eos qui ratione voluptatem sequi nesciunt neque porro quisquam est, qui dolorem ipsum quiaolor sit amet." />
      <MissionItem iconThree title="Profissionais Qualificados" description="Quuntur magni dolores eos qui ratione voluptatem sequi nesciunt neque porro quisquam est, qui dolorem ipsum quiaolor sit amet." />
    </Mission>

    <CtaContainer
      headline="Evolua seu conhecimento"
      title="Os melhores debates"
      subtitle="Converse com pessoas de qualquer lugar"
      buttonUrl="/register/"
      buttonText="Cadastre-se"
      background={require('../images/bg_cta2.jpg')}
    />

    <Testimonial />
  </Layout>
)

export default AboutPage
