import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Container, Row, Col } from "reactstrap"


import "./style.scss"

const CtaContainer = props => (
  <section
    className={`cta-container`}
    style={{ backgroundImage: `url(${props.background})` }}
  >
    <Container>
      <Row>
        <Col className="content">
          <span>{props.headline}</span>
          <h3>{props.title}</h3>
          <h3>{props.subtitle}</h3>
          <Link to={props.buttonUrl} className={`button orange`}>{props.buttonText}</Link>
        </Col>
      </Row>
    </Container>
  </section>
)

CtaContainer.propTypes = {
  headline: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonUrl: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
}

CtaContainer.defaultProps = {
  headline: "",
  title: "",
  subtitle: "",
  buttonUrl: "/register/",
  buttonText: "Inscreva-se",
}

export default CtaContainer
